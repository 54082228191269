@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  /* border-color: theme('borderColor.DEFAULT', currentColor); */
}



body {
  /* background-color: #7285bb; */
  margin: 0;
  /* overflow: hidden; */

}

ul {
  padding: 0;
  margin-left: 5%;
  margin-bottom: 0%;
  margin-top: 3%;
}

/* hides arrows on number input field for modal did not work in tailwind */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* The switch - the box around the slider */
input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tippy-box[data-theme~='notes'] {
  background-color: #f9e168;
  color: #232323;
  font-size:large;
  padding: 8px 4px;
  border: 3px solid rgba(0, 0, 0, 0.6);
  overflow-wrap: break-word;
}

.tippy-box[data-theme~='notes'][data-placement^='top-end'] > .tippy-arrow::before {
  width: 1px;
  margin-top:12px;
  top: 5px;
  border-top-color: black;
}
.tippy-box[data-theme~='notes'][data-placement^='bottom'] > .tippy-arrow::before {
  margin-top: 2px;

  border-bottom-color: black;
}
.tippy-box[data-theme~='notes'][data-placement^='left'] > .tippy-arrow::before {
  margin-top: 2px;

  border-left-color: black;
}
.tippy-box[data-theme~='notes'][data-placement^='right'] > .tippy-arrow::before {
  margin-top: 2px;

  border-right-color: black;
}