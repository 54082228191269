
.checkCell {
    width: 3%;
}

.SymbolCell {

    /* box-sizing: border-box; */
}

.NameCell {

}

.PriceCell {
    color: inherit;
}

.pagination {
    display: inline-block;
    background-color: white;
    color: #416dea;
    border: 2px solid #416dea;
    font-size: 20px;
    padding: 4px 20px;
    text-decoration: none;
    transition: background-color 0.5s;
    cursor: pointer;
}

.pagination:hover,
.pagination:active {
    color: white;
    background-color: #416dea;
}

.logos {
    width: 25px;
    max-height: 30px;
    border-radius: 2px;
    margin-bottom: -6px;
    margin-right: 5px;
}

.pagination button.active {
    color: white;
}

.pagination on:hover:not(.active) {
    background-color: #416dea;
}

.listOptions {
    height: 1.8em;
    padding-right: 1em;
    padding-left: 1em;
    font-weight: 600;
    font-size: 15px;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
}

.listOptions:hover {
    background-color: #fec458;
}

.tab:hover {
    background-color: #fec458;
}

.settings {
    margin-top: 4%;
    margin-left: 6%;
    margin-right: 10%;
    margin-bottom: 7%;
    font-size: 16px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}

.selectsLeft {
    margin-top: 4%;
    margin-left: 6%;
    margin-right: 10%;
    margin-bottom: 7%;
    font-size: 16px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    padding-bottom: 5%;
}

.selectsRight {
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 6%;
    margin-bottom: 7%;
    font-size: 16px;
    padding: 5px 10px;
    background-color: white;
    border-radius: 10px;
    padding-bottom: 5%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.sliderRound {
    border-radius: 34px;
}

.sliderRound:before {
    border-radius: 50%;
}

.settings_table {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    border-collapse: collapse;
    border-radius: 5px;
    width: 90%;
}

.settings_table td {
    border-bottom: #2196F3 solid 1px;
    white-space: normal;
}

.settings_table tr:last-of-type td {
    border-bottom: none;
}

.settings_table tr:nth-child(even),
.settings_table tr:nth-child(odd) {
    background-color: white;
}

.settings_table tr:first-of-type td:first-of-type {
    border-top-left-radius: 8px;
}

.settings_table tr:first-of-type td:last-of-type {
    border-top-right-radius: 8px;
}

.settings_table tr:hover {
    background-color: #2195f37b;
}

/* Stock Table Styles */

.stock_table td a {
    display: block;
    justify-content: left;
    padding: 4px;
    border-radius: 4px;
    min-width: 3ch;
    cursor: pointer;
}

.stock_table td a,
.stock_table td a:visited {
    text-align: left;
    text-decoration: none;
    display: inline-block;
    color: inherit;
}

.stock_table td a:hover,
.stock_table td a:active {
    text-decoration: none;
    color: inherit;
    background-color: #c77dff;
    /* color: #ffffff; */
    border: 2px solid #000000;
    font-weight: bold;
    /* text-decoration: underline; */
}

.stock_table {
    border-collapse: collapse;
    cursor: default;
    width: 100%;
padding: 16px;
}

.stock_table th:first-of-type {
    border-top-left-radius: 8px;
}

.stock_table th:last-of-type {
    border-top-right-radius: 8px;
    padding-right: 20px;

}

.stock_table tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 8px;
}

.stock_table tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 8px;
}

.stock_table tr:last-of-type td {
    border-bottom: none;
    padding-bottom: 4px;

}

.stock_table th,
.stock_table td {
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
    position: relative;
    text-align: center;
}

.stock_table th {


}

.stock_table th:hover {
    color: #fec458;
}

.stock_table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.stock_table tr:nth-child(odd) {
    background-color: #fff;
}

.stock_table tr:hover {
    background-color: rgb(189, 190, 255);
    /* cursor: copy; */
}



#box {
    width: 60px;
    height: 60px;
    background-color: green;
    border: 6px solid blue;
    margin: auto auto;
    padding: auto auto;
}